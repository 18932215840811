<template>
  <span class="icon d-icon" :class="[size, position, 'icon-'+icon]" v-html="svg">
  </span>
</template>

<script>
// import axios from 'axios';
// import wrapper from 'axios-cache-plugin'

export default {
  data() {
    return {
      svg: "",
      packs: {
        far: "regular",
        fas: "solid",
        fab: "brands"
      }
    }
  },
  props: {
    pack: {
      type: String,
      default: '',
    },
    icon:{
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'is-small',
    },
    position:{
      type: String,
      default: '',
    }
  },
  computed: {
    url(){
      return `/fa/svgs/${this.packs[this.pack]}/${this.icon}.svg`
    }
  },
  watch: {
    icon(){
      this.FetchIcon()  
    }
  },
  methods: {
    async FetchIcon(){
      try{
        const response = await fetch(this.url)
        if(response.status== 200){
          this.svg = await response.text(); 
        }
      }catch(e){
        console.error("Error descargando svg", this.pack, this.icon)
      }
    }
  },  
  async mounted(){
    this.FetchIcon()
  }
}
</script>